import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { FaAngleRight, FaPowerOff, FaSpinner } from "react-icons/fa";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { DataGrid } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';

import { useSettings } from '../context/SettingsContext'
import { API_URL } from './parts/Constants';

const Site = ({ refreshSidebar }) => {
  let failureColumns = [
    { field: 'response', headerName: 'Error Message', flex:2 },
    { field: 'notified', headerName: 'User Notified', flex:1 },
    { field: 'created', headerName: 'Date', flex:1 }
  ];
  let notificationColumms = [
    { field: 'type', headerName: 'Delivery Type', flex:1 },
    { field: 'message', headerName: 'Message', flex:2 },
    { field: 'sent', headerName: 'Sent', flex:1 }
  ]
  const [mode, setMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );

  const settings = useSettings();
  const navigate = useNavigate();
  const { id } = useParams();
  const token = settings.userToken;
  const [data, setData] = useState(null);
  const [failures, setFailures] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [graphData, setGraphData] = useState(null);

  const [isFailuresVisible, setFailuresVisible] = useState(true);
  const [isNotificationsVisible, setNotificationsVisible] = useState(true);
  const [isMonthDataVisible, setMonthDataVisible] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [showError, setShowError] = useState(false)

  /**
   * 
   */
  useEffect(() => {
    // doc title
    document.title = "Site Data | SiteStatusMontoring.com"

    // detect dark mode
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => {
      setMode(mediaQuery.matches ? 'dark' : 'light');
    };
    mediaQuery.addEventListener('change', handleChange);

    if (token) {
      var myHeaders = new Headers();
      myHeaders.append("wsm-session-token", token);

      var formdata = new FormData();
      formdata.append("id", id);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: formdata
      };

      fetch(API_URL + '/getsite', requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.code === 200) {
            if (result.site) {
              setData(result.site)
            }
            
            if (result.failures) {
              setFailures(result.failures)
            } else {
              setFailures(null)
            }
            
            if (result.notifications) {
              setNotifications(result.notifications)
            } else {
              setNotifications(null)
            }
            
            if (result.checks) {
              setGraphData(result.checks)
            } else {
              setGraphData(null)
            }
          } else {
            //console.log(result)
          }
        })
        .catch(error => console.log('error', error));
    }

    // Cleanup function to remove the listener when the component unmounts
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [id]);

  /**
   * 
   */
  const theme = createTheme({
    palette: {
      mode: mode,
    },
  });

  /**
   * 
   */
  const showFailures = () => {
    setFailuresVisible(prevVisible => !prevVisible);  // toggle visibility
  };

  /**
   * 
   */
  const showNotificationData = () => {
    setNotificationsVisible(prevVisible => !prevVisible);  // toggle visibility
  };

  /**
   * 
   */
  const showMonthData = () => {
    setMonthDataVisible(prevVisible => !prevVisible);  // toggle visibility
  };

  /**
   * 
   */
  const handleDelete = () => {
    setIsDeleting(true)
    const userConfirmation = window.confirm("Are you sure you want to proceed? Deleting a site will remove all history and information related to it from our application.");

    if (userConfirmation) {
      var myHeaders = new Headers();
      myHeaders.append("wsm-session-token", token);

      var formdata = new FormData();
      formdata.append("site_id", id);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(API_URL + "/deletesite", requestOptions)
        .then(response => response.json())
        .then(result => {
          setIsDeleting(false)

          if (result.code == 200) {
            refreshSidebar()
            navigate('/')
          } else {
            setAlertMessage(result.message)
            setShowError(true)
          }
        })
        .catch(error => console.log('error', error));
    } else {
      setIsDeleting(false)
    }
  }

  return (
    <div className="relative pt-24 pb-32 dark:bg-primary">
      <div className="px-4 md:px-10 max-w-5xl mx-5 md:mx-auto">
        {data ? (
          <div>
            {showError ? (
              <Alert severity="error" variant="filled" className='my-12' onClose={() => {setShowError(false)}}>
                {alertMessage}
              </Alert>
            ) : (<></>)}

            <div className='flex justify-between items-center py-3 px-8 bg-secondary rounded-2xl drop-shadow'>
              <h1 className='my-0'>{data.title}</h1>
              <div className='flex justify-end items-center ml-2'>
                <span className='text-sm'>Current Status:</span>
                <FaPowerOff title='Current Status' className={`ml-2 text-xl ${(data.status == 1) ? 'text-green-500' : 'text-red-500'}`} />
              </div>
            </div>
            <p className='text-right mt-2 mb-8 text-sm'>Last Check: {data.last_check} UTC</p>

            {graphData ? (
              <div className='items-center py-3 px-8 pb-8'>
                <h5 onClick={showMonthData} className="py-3 px-8 md:min-w-full text-blueGray-500 bg-secondary rounded-b-2xl rounded-l-2xl drop-shadow font-bold mb-5 no-underline cursor-pointer flex items-center justify-between">
                  <span>Daily Stats</span>
                  <FaAngleRight className={`text-sm mx-2 transition-transform duration-300 ${isMonthDataVisible ? 'rotate-90' : ''}`} />
                </h5>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    width={500}
                    height={400}
                    data={graphData}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="created" />
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="status" stroke="#8884d8" fill="#8884d8" />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            ) : (<></>)}

            {failures ? (
              <div className='items-center py-3 px-8 pb-8'>
                <p className='dark:text-white mb-5'>Below is a list of failed checks logged by our system. </p>
                <h5 onClick={showFailures} className="py-3 px-8 mb-0 md:min-w-full text-blueGray-500 bg-secondary rounded-t-2xl drop-shadow font-bold no-underline cursor-pointer flex items-center justify-between">
                  <span>Documented Failures</span>
                  <FaAngleRight className={`text-sm mx-2 transition-transform duration-300 ${isFailuresVisible ? 'rotate-90' : ''}`} />
                </h5>
                <div className={isFailuresVisible ? '' : 'hidden'}>
                  <ThemeProvider theme={theme}>
                    <DataGrid
                      rows={failures}
                      columns={failureColumns}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 10 },
                        },
                      }}
                      pageSizeOptions={[10, 15]}
                      className='dark:text-white'
                    />
                  </ThemeProvider>
                </div>
              </div>
            ) : (
              <div className='my-5 text-center'>
                <h2 className='dark:text-white'>No downtime has been documented for this website. Awesome!</h2>
              </div>
            )}

            {notifications ? (
              <div className='items-center py-3 px-8 pb-8'>
                <h5 onClick={showNotificationData} className="py-3 px-8 mb-0 md:min-w-full text-blueGray-500 bg-secondary rounded-t-2xl drop-shadow font-bold no-underline cursor-pointer flex items-center justify-between">
                  <span>Notifications</span>
                  <FaAngleRight className={`text-sm mx-2 transition-transform duration-300 ${isNotificationsVisible ? 'rotate-90' : ''}`} />
                </h5>
                <div className={isNotificationsVisible ? '' : 'hidden'}>
                  <ThemeProvider theme={theme}>
                    <DataGrid
                      rows={notifications}
                      columns={notificationColumms}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 10 },
                        },
                      }}
                      pageSizeOptions={[10, 15]}
                    />
                  </ThemeProvider>
                </div>
              </div>
            ) : (<></>)}

            <div className='pt-10'>


              {isDeleting ? (
                <div className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-md font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer">
                  <FaSpinner className="text-white animate-spin" />
                </div>
              ) : (
                <a onClick={handleDelete} className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-md font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer">
                  Delete Website
                </a>
              )}
            </div>
            
          </div>  
        ) : (
          <p className='text-2xl justify-center flex items-center text-center'>
            <span>Loading Site...</span>
            <FaSpinner className='text-sm ml-2 animate-spin' />
          </p>
        )}
      </div>
    </div>
  )
}

export default Site

/*
<code><pre>{JSON.stringify(data, null, 2)}</pre></code>
<code><pre>{JSON.stringify(failures, null, 2)}</pre></code>
*/