import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { FaCheck, FaSpinner } from "react-icons/fa";
import Alert from '@mui/material/Alert';

import { useSettings } from '../context/SettingsContext'
import { API_URL } from './parts/Constants';

const ContactMethod = () => {
  const settings = useSettings();
  const token = settings.userToken;
  const { id } = useParams();
  const navigate = useNavigate();
  //var 
  const [counter, setCounter] = useState(0)
  const [method, setMethod] = useState('')
  const [title, setTitle] = useState('')
  const [vcode, setVcode] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  //toggles
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showError, setShowError] = useState(false)
  const [resending, setResending] = useState(false)

  /**
   * 
   */
  useEffect(() => {
    // doc title
    document.title = "Contact Method | SiteStatusMontoring.com"

    var myHeaders = new Headers();
    myHeaders.append("wsm-session-token", token);

    var formdata = new FormData();
    formdata.append("id", id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "/getmethod", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.code == 200) {
          setMethod(result)
          setTitle(result.method.title)

          if (result.method.verified == 1) {
            setIsVerified(true)
          }
        } else {
          navigate('/settings/methods'); 
        }
      })
      .catch(error => {
        navigate('/settings/methods'); 
      });
  }, [counter]);

  /**
   * 
   * @param {*} event 
   */
  const handleSubmit = (event) => {
    event.preventDefault()
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setIsSubmitting(true)

    if (!title) {
      setAlertMessage('A title is required.')
      setShowError(true)
      setIsSubmitting(false)
      return
    }

    var myHeaders = new Headers();
    myHeaders.append("wsm-session-token", token);

    var formdata = new FormData();
    formdata.append("title", title);
    formdata.append("id", id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "/updatemethod", requestOptions)
      .then(response => response.json())
      .then(result => {
        setAlertMessage(result.message)

        if (result.code == 200) {
          setShowAlert(true)
        } else {
          setShowError(true)
        }

        setIsSubmitting(false)
      })
      .catch(error => console.log('error', error));
  }

  /**
   * 
   */
  const handleVerification = (event) => {
    event.preventDefault()
    setIsVerifying(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })

    var myHeaders = new Headers();
    myHeaders.append("wsm-session-token", token);

    var formdata = new FormData();
    formdata.append("code", vcode);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "/vcode", requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log(result)

        if (result.code == 200) {
          refreshComponent()
        } else {
          setAlertMessage(result.message)
          setShowError(true)
          setVcode('')
        }

        setIsVerifying(false)
      })
      .catch(error => console.log('error', error));
  }

  /**
   * 
   */
  const handleDelete = () => {
    setIsDeleting(true)
    const userConfirmation = window.confirm("Are you sure you want to proceed? Any sites using this method will no longer notify you until you select a different method.");

    if (userConfirmation) {
      var myHeaders = new Headers();
      myHeaders.append("wsm-session-token", token);

      var formdata = new FormData();
      formdata.append("method_id", id);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(API_URL + "/removecm", requestOptions)
        .then(response => response.json())
        .then(result => {
          setIsDeleting(false)

          if (result.code == 200) {
            navigate('/settings/methods'); 
          } else {
            setAlertMessage(result.message)
            setShowError(true)
          }
        })
        .catch(error => console.log('error', error));
    } else {
      setIsDeleting(false)
    }
  }

  /**
   * 
   */
  const resendCode = () => {
    setResending(true)

    var myHeaders = new Headers();
    myHeaders.append("wsm-session-token", token);

    var formdata = new FormData();
    formdata.append("method_id", id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "/resendver", requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log(result)

        if (result.code == 200) {
          setAlertMessage(result.message)
          setShowAlert(true)
        } else {
          setAlertMessage(result.message)
          setShowError(true)
        }

        setResending(false)
      })
      .catch(error => console.log('error', error));
  }

  return (
    <div className="relative pt-24 pb-32">
      <div className="px-4 md:px-10 max-w-5xl mx-5 md:mx-auto">
        <h1 className='mb-8 dark:text-white'>Contact Method.</h1>
        <hr className='dark:border-white border-primary mb-10' />

        {showAlert ? (
          <Alert severity="success" variant="filled" className='my-12' onClose={() => {setShowAlert(false)}}>
            {alertMessage}
          </Alert>
        ) : (<></>)}

        {showError ? (
          <Alert severity="error" variant="filled" className='my-12' onClose={() => {setShowError(false)}}>
            {alertMessage}
          </Alert>
        ) : (<></>)}

        {method ? (
          <div>
            <h3 className='dark:text-white font-normal'><span className='font-bold mr-2'>Title:</span> {method.method.title}</h3>
            <h3 className='dark:text-white font-normal'><span className='font-bold mr-2'>Method:</span> <span className='capitalize'>{method.method.method}</span></h3>
            {renderData()}

            {isVerified ? (
              <p className='flex items-center'><span className='dark:text-white'>Contact Method Verified</span> <FaCheck className="text-green-500 ml-3" /></p>
            ) : (
              <div className='p-3 lg:p-5 border border-solid dark:border-secondary border-primary rounded-3xl my-10 flex justify-between items-center'>
                <p className='dark:text-white'>Verify contact method</p>
                <div className='flex items-center'>
                  <form onSubmit={handleVerification} className='flex items-center'>
                    <div className='flex justify-center items-center'>
                      <input
                        id="title"
                        type="text"
                        placeholder='Verfication Code'
                        value={vcode}
                        onChange={(event) => {
                          setVcode(event.target.value)
                        }}
                        onBlur={(event) => {
                          setVcode(event.target.value)
                        }}
                        className="text-lg rounded-2xl py-2 mr-5 px-5 w-100 border border-solid border-primary"
                      />
                      {isVerifying ? (
                        <div className="group relative py-2 px-3 border border-transparent text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer">
                          <FaSpinner className="text-white animate-spin" />
                        </div>
                      ) : (
                        <input type="submit" value="Submit" className="group relative py-1 px-3 border border-transparent text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer" />
                      )} 
                    </div>
                  </form>
                  {resending ? (
                    <div className="ml-2 group relative w-full flex justify-center py-2 px-3 border border-transparent text-md font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer">
                      <FaSpinner className="text-white animate-spin" />
                    </div>
                  ) : (
                    <a onClick={resendCode} className="ml-2 group relative w-full flex justify-center py-1 px-3 border border-transparent text-md font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer">
                      Resend
                    </a>
                  )}
                  
                </div>
              </div>
            )} 

            <hr className='dark:border-white border-primary mb-10 mt-20' />
            <p className='mb-2 dark:text-white'>You may edit the title below. You can not edit any specific data other than the title. If you wish to change the email address or edit other specific data, you must create a different contact method.</p>
            <form onSubmit={handleSubmit} className='mb-5 mt-10'>
              <input
                id="title"
                type="text"
                placeholder='Contact Method Title'
                value={title}
                onChange={(event) => {
                  setTitle(event.target.value)
                }}
                onBlur={(event) => {
                  setTitle(event.target.value)
                }}
                className="text-lg rounded-2xl py-3 px-5 w-full border border-solid border-primary"
              />

              {isSubmitting ? (
                <div className="group relative w-full flex justify-center mt-10 py-3 px-4 border border-transparent text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer">
                  <FaSpinner className="text-white animate-spin" />
                </div>
              ) : (
                <input type="submit" value="Submit" className="group relative mt-10 w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer" />
              )} 
            </form>

            {isDeleting ? (
              <div className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-md font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer">
                <FaSpinner className="text-white animate-spin" />
              </div>
            ) : (
              <a onClick={handleDelete} className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-md font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer">
                Delete Contact Method
              </a>
            )} 
          </div>
        ) : (<></>)}
        
      </div>
    </div>
  )

  function renderData() {
    switch (method.method.method) {
      case 'email':
        return (
          <h3 className='dark:text-white font-normal'><span className='font-bold mr-2'>Email Address:</span> {method.data.email}</h3>
        )
      break;

      case 'telegram':
        return (
          <>
            <h3 className='dark:text-white font-normal'><span className='font-bold mr-2'>API Key:</span> {method.data.api_key}</h3>
            <h3 className='dark:text-white font-normal'><span className='font-bold mr-2'>Chat ID:</span> {method.data.chat_id}</h3>
          </>
        )
      break;
    }
  }

  /**
   * Refreshes component
   */
  function refreshComponent() {
    setCounter(prevCounter => prevCounter + 1); // Any change in state triggers a re-render
  };
}

export default ContactMethod