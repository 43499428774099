import React, { useEffect, useState } from 'react'
import { FaCirclePlus } from "react-icons/fa6";
import { Link } from 'react-router-dom'

const Button = (props) => {
  const [icon, setIcon] = useState()

  useEffect(() => {
    if (props.icon) {
      getIcon(props.icon)
    }
  }, []);

  const getIcon = (icon) => {
    if (icon === 'FaCirclePlus') {
      setIcon(<FaCirclePlus className='ml-2' />)
    }
  }

  return (
    <Link 
      to={props.href} 
      className={`text-secondary font-semibold border-2 border-accent border-solid flex items-center justify-center hover:bg-transparent bg-accent hover:text-accent rounded-full ${props.extraClasses}`}
    >
      {props.title || 'Button'}
      {icon}
    </Link>
  )
}

export default Button