import React from 'react'
import { FaBan } from "react-icons/fa";

const NotFound = () => {

  return (
    <div className="relative pt-14">
      <div className='text-center min-h-screen -mt-14 flex mx-5 md:mx-auto max-w-2xl flex-col justify-center items-center'>
        <h1 className='text-center text-2xl lg:text-8xl dark:text-white mb-10'>Not Found.</h1>
        <FaBan
          className={'text-red-500 text-5xl lg:text-9xl mb-10'}
        />
        <h3 className='lg:text-xl text-md dark:text-white'>Sorry, but the page you are looking for is not found. Please using the navigation in the sidebar or in the header to navigate the site.</h3>
      </div>
    </div>
  )
}

export default NotFound