import React, { useEffect } from 'react';

const Support = () => {
  /**
   * 
   */
  useEffect(() => {
    document.title = "Support | SiteStatusMontoring.com"
  }, []);

  return (
    <div className="relative pt-14">
      <div className='min-h-screen -mt-14 bg-primary mx-5 md:mx-auto max-w-4xl'>
        <div id='support-form' className='pt-28 pb-12'>
          <h1 className='dark:text-white'>Support Ticket</h1>
          <p className='dark:text-white mb-4'>Having issues? Want to request a feature? Please use the form below to let our team now. Once your email has been received, our team will reach out if needed. We are a new platform, so please be patient with our response time.</p>

          <iframe
            id="JotFormIFrame-233404725248152"
            title="SiteStatusMonitor Support Ticket Form"
            allowFullScreen={true}
            allow="camera"
            src="https://form.jotform.com/233404725248152"
            style={{width:'100%', border:'none', height:1800, marginTop:75, marginBottom:75, backgroundColor:'transparent'}}
          ></iframe>

          <p className='dark:text-white text-center'>If the form doesn't load below, please use this link: <a className='underline' target='_blank' rel="noopener noreferrer nofollow" href="https://form.jotform.com/233404725248152">Support form</a></p>
        </div>
      </div>
    </div>
  )
}

export default Support