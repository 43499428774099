import React, { useEffect, useState } from 'react'
import { useSettings } from '../context/SettingsContext'
import { Link } from 'react-router-dom';
import { FaCheck, FaSpinner } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import Alert from '@mui/material/Alert';

import { API_URL } from './parts/Constants';

const Settings = () => {
  const settings = useSettings();
  const token = settings.userToken;
  //vars
  const [counter, setCounter] = useState(0)
  const [methods, setMethods] = useState('')
  const [allowMoreMethods, setAllowMoreMethods] = useState(0)
  const [user, setUser] = useState();
  const [userName, setUserName] = useState()
  const [userCompany, setUserCompany] = useState()
  const [userEmail, setUserEmail] = useState()
  const [userNewsletter, setUserNewsletter] = useState()
  const [editUser, setEditUser] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showError, setShowError] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  /**
   * 
   */
  useEffect(() => {
    //doc title
    document.title = "Settings | SiteStatusMontoring.com"
    
    //get options for user
    var myHeaders = new Headers();
    myHeaders.append("wsm-session-token", token);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(API_URL + "/getsettings", requestOptions)
      .then(response => response.json())
      .then(results => {
        //console.log(results);

        if (results.code == 200) {
          setMethods(results.methods)
          setAllowMoreMethods(results.canAddMoreMethods)
          
          setUser(results.user)
          setUserName(results.user.name)
          setUserCompany(results.user.company)
          setUserEmail(results.user.email)
          if ( results.user.newsletter == 1 ) {
            setUserNewsletter(true)
          } else {
            setUserNewsletter(false)
          }
          
        }    
      })
      .catch(error => console.log('error', error));
    
  }, [counter])

  const submitEditUser = (event) => {
    event.preventDefault();
    setIsSubmitting(true)
    
    if (!userName) {
      setAlertMessage('A name is required. Please check your values and try again.')
      setShowError(true)
      setIsSubmitting(false)
      return;
    }

    if (!userCompany) {
      setAlertMessage('A company is required. Please check your values and try again.')
      setShowError(true)
      setIsSubmitting(false)
      return;
    }

    if (!userEmail) {
      setAlertMessage('A email address is required. Please check your values and try again.')
      setShowError(true)
      setIsSubmitting(false)
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("wsm-session-token", token);

    var formdata = new FormData();
    formdata.append("name", userName);
    formdata.append("company", userCompany);
    formdata.append("email", userEmail);
    formdata.append("newsletter", userNewsletter);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "/savesettings", requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log(result)
        if (result.code == 400) {
          setAlertMessage(result.message)
          setShowError(true)
        } else {
          setCounter(counter => !counter)
          setEditUser(false)
        }

        setIsSubmitting(false)
      })
      .catch(error => console.log('error', error));
  }

  return (
    <div className="relative pt-24 pb-32">
      <div className="px-4 md:px-10 max-w-5xl mx-5 md:mx-auto">
        <h1 className='mb-8 dark:text-white'>Settings</h1>
        <hr className='dark:border-white border-primary mb-5' />
        <p className='dark:text-white mb-20'>Welcome to your account's settings page. </p>
        
        {showAlert ? (
          <Alert severity="success" variant="filled" className='my-12' onClose={() => {setShowAlert(false)}}>
            {alertMessage}
          </Alert>
        ) : (<></>)}

        {showError ? (
          <Alert severity="error" variant="filled" className='my-12' onClose={() => {setShowError(false)}}>
            {alertMessage}
          </Alert>
        ) : (<></>)}

        <div className='mb-20'>
          <h2 className='dark:text-white mb-10'>Account Information</h2>
          {user && (
            <div className='max-w-5xl mx-auto p-8 lg:p-16 border border-solid flex-col dark:border-secondary border-primary rounded-3xl my-10 md:flex justify-between items-center'>
              {editUser ? (
                <form className='w-full' onSubmit={submitEditUser}>
                  <div className='flex items-center mb-4'>
                    <p className='dark:text-white mr-4 w-24'>Name:</p>
                    <input
                      id="name"
                      type="text"
                      placeholder='Name'
                      value={userName}
                      onChange={(event) => {
                        setUserName(event.target.value)
                      }}
                      onBlur={(event) => {
                        setUserName(event.target.value)
                      }}
                      className="text-lg rounded-2xl py-2 px-5 w-full"
                    />
                  </div>

                  <div className='flex items-center mb-4'>
                    <p className='dark:text-white mr-4 w-24'>Company:</p>
                    <input
                      id="company"
                      type="text"
                      placeholder='Company'
                      value={userCompany}
                      onChange={(event) => {
                        setUserCompany(event.target.value)
                      }}
                      onBlur={(event) => {
                        setUserCompany(event.target.value)
                      }}
                      className="text-lg rounded-2xl py-2 px-5 w-full"
                    />
                  </div>

                  <div className='flex items-center mb-4'>
                    <p className='dark:text-white mr-4 w-24'>Email:</p>
                    <input
                      id="company"
                      type="text"
                      placeholder='Email Address'
                      value={userEmail}
                      onChange={(event) => {
                        setUserEmail(event.target.value)
                      }}
                      onBlur={(event) => {
                        setUserEmail(event.target.value)
                      }}
                      className="text-lg rounded-2xl py-2 px-5 w-full"
                    />
                  </div>

                  <div className='flex items-center mb-4 w-full dark:text-white cursor-pointer' onClick={() => setUserNewsletter(userNewsletter => !userNewsletter)}>
                    <span className='flex items-center mt-5'>
                      <div className='border border-solid p-1 dark:border-white border-primary rounded mr-2'>
                        {userNewsletter == 1 ? ( <FaCheck className='dark:white text-base' />) : (<FaXmark className='dark:text-primary text-secondary text-base' />)}
                      </div>
                      Subscribe to product updates and changes.
                    </span>
                  </div>

                  {isSubmitting ? (
                    <div className="group relative w-full flex justify-center mt-10 py-3 px-4 border border-transparent text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer">
                      <FaSpinner className="text-white animate-spin" />
                    </div>
                  ) : (
                    <input type="submit" value="Submit" className="group relative mt-10 w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer" />
                  )}  

                  <p className='text-center mt-2'>
                    <a onClick={() => {setEditUser(editUser => !editUser)}} className="text-center dark:text-white cursor-pointer">
                      Cancel
                    </a>
                  </p>
                </form>
              ) : (
                <div className='w-full'>
                  <p className='dark:text-white text-2xl leading-9 w-full mb-10'>
                    <span className='font-bold'>Name:</span> {user.name} <br />
                    <span className='font-bold'>Company:</span> {user.company} <br />
                    <span className='font-bold'>Email:</span> {user.email} <br />
                    <span className='font-bold'>Level:</span> {user.level} <br />
                    <span className='flex items-center mt-5'>
                      <span className='border border-solid p-1 dark:border-white border-primary rounded mr-2'>
                        {user.newsletter == 1 ? ( <FaCheck className='dark:white text-base' />) : (<FaXmark className='dark:text-primary text-secondary text-base' />)}
                      </span>
                      Subscribe to product updates and changes.
                    </span>
                  </p>

                  <a onClick={() => {setEditUser(editUser => !editUser)}} className="group relative w-full flex justify-center py-3 px-4 border border-accent text-md font-medium rounded-md text-white bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer">
                    Edit
                  </a>
                </div>
              )}
            </div>
          )}
        </div>

        <div className='mb-20'>
          <h2 className='dark:text-white mb-10'>Contact Methods</h2>
          <div className='max-w-5xl mx-auto p-8 lg:p-16 border border-solid dark:border-secondary border-primary flex-col rounded-3xl my-10 md:flex justify-between items-center'>
            {methods ? (
              methods.map((method, index) => (
                <Link to={`/settings/methods/${method.id}`} key={index} className="py-3 px-8 md:min-w-full text-blueGray-500 bg-secondary rounded-2xl drop-shadow mb-5 no-underline cursor-pointer flex items-center justify-between">
                  <span className='font-bold'>{method.title} <span className='font-bold capitalize text-xs'>({method.method})</span></span>
                  <span className='capitalize'>Verified: {method.verified == 0 ? (<span className='text-red-500'>No</span>) : (<span className='text-green-500'>Yes</span>)}</span>
                </Link>
              ))
            ) : (<></>)}
            {allowMoreMethods ? (
              <Link to={"/settings/methods"} className="group relative w-full flex justify-center mt-10 py-3 px-4 border text-md font-medium rounded-md text-white bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer border-solid border-accent">Add/Manage Contact Methods</Link>
            ) : (
              <div className='w-full'>
                <Link to={"/settings/methods"} className="group relative w-full flex justify-center mt-10 py-3 px-4 border text-md font-medium rounded-md text-white bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer border-solid border-accent">Manage Contact Methods</Link>
                <p className='dark:text-white mt-5 text-center'>Note: Contact Method limit reached.</p>
              </div>
            )}
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default Settings