import React from 'react'

import { Link } from 'react-router-dom';
import { FaBars, FaPowerOff } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

import { useSettings } from '../../context/SettingsContext'

export const Nav = ({ menuToggle, menuOpen }) => {
   const settings = useSettings();

   const logout = () => {
      localStorage.removeItem('authKey')
      //window.location.reload();
      window.location.href = '/'
   }

   return (
      <nav className="absolute top-0 left-0 w-full z-10 bg-accent lg:flex-row lg:flex-nowrap lg:justify-start flex items-center py-1 px-4 border-b border-solid border-white">
         <div className="w-full mx-aut0 items-center flex justify-between lg:flex-nowrap flex-wrap lg:px-6 px-4">
            <button className="ml-auto cursor-pointer text-white text-xl leading-none px-3 py-1 border border-solid border-blueGray-400 rounded bg-transparent block outline-none focus:outline-none text-blueGray-300 mr-5" type="button" onClick={menuToggle}>
               {menuOpen ? <IoClose /> : <FaBars />}
            </button>
            <Link to={"/"} className="text-blueGray-800 text-white text-sm uppercase inline-block font-semibold my-3">
               Home
            </Link>
            <span className='text-white mx-4 md:hidden'>|</span>
            <Link to={"/sites"} className="text-blueGray-800 text-white text-sm uppercase inline-block font-semibold my-3 md:hidden">
               Sites
            </Link>
            <span className='text-white mx-4'>|</span>
            <Link to={"/settings"} className="text-blueGray-800 text-white text-sm uppercase inline-block font-semibold my-3">
               Settings
            </Link>
            <span className='text-white mx-4'>|</span>
            <Link to={"/support"} className="text-blueGray-800 text-white text-sm uppercase inline-block font-semibold my-3">
               Support
            </Link>
            
            <div className="items-center w-full lg:flex lg:w-auto flex-grow duration-300 transition-all ease-in-out lg:h-auto-important hidden justify-end">
               <button onClick={() => logout()} className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center">
                  <FaPowerOff className='mr-2' />
                  Logout
               </button>
            </div>
         </div>
      </nav>
   )
}
