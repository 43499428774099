import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { FaSpinner, FaCheck, FaTimes, FaBan, FaThermometerFull } from "react-icons/fa";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { useSettings } from '../context/SettingsContext'
import { API_URL } from './parts/Constants';
import Button from './parts/Button';

const Add = ({ refreshSidebar }) => {
  const settings = useSettings();
  const token = settings.userToken;
  const { id } = useParams();
  
  //vars
  const [url, setUrl] = useState('https://');
  const [options, setOptions] = useState('')
  const [threshold, setThreshold] = useState('')
  const [interval, setInterval] = useState('')
  const [mode, setMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('')
  const [newId, setNewId] = useState('')

  const [chosenMethod, setChosenMethod] = useState('')
  const [chosenMethodTitle, setChosenMethodTitle] = useState('')

  const [amount, setAmount] = useState(0)
  const [allowed, setAllowed] = useState(0)
  const [remaining, setRemaining] = useState(0)
  
  //toggles
  const [canAdd, setCanAdd] = useState(true);
  const [showError, setShowError] = useState(false);
  const [validationResult, setValidationResult] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [addError, setAddError] = useState(1);
  const [reloadTrigger, setReloadTrigger] = useState(0);

  /**
   * 
   */
  useEffect(() => {
    //doc title
    document.title = "Add Site | SiteStatusMontoring.com"

    //detect dark mode
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => {
      setMode(mediaQuery.matches ? 'dark' : 'light');
    };
    mediaQuery.addEventListener('change', handleChange);
    
    //get options for user
    //if (!options) {
      var myHeaders = new Headers();
      myHeaders.append("wsm-session-token", token);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(API_URL + "/canaddwithoptions", requestOptions)
        .then(response => response.json())
        .then(results => {
          //console.log(results);

          if (results.code == 400) {
            setCanAdd(false)
          } else if (results.code == 401) {
            setAddError(2)
            setCanAdd(false)
          }

          setOptions(results)
          setAllowed(results.allowed)
          setAmount(results.amount)
          setRemaining(results.remaining)
        })
        .catch(error => console.log('error', error));
    //}
    
    // Cleanup function to remove the listener when the component unmounts
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [reloadTrigger])

  /**
   * 
   */
  const theme = createTheme({
    palette: {
      mode: mode,
    },
  });

  /**
   * 
   * @param {*} event 
   */
  const handleThreshold = (event) => {
    setThreshold(event.target.value);
  };

  /**
   * 
   * @param {*} event 
   */
  const handleInterval = (event) => {
    setInterval(event.target.value);
  };

  /**
   * 
   * @param {*} e 
   * @returns 
   */
  const handleUrlChange = async (e) => {
    const value = e.target.value;
    setUrl(value);

    if (value.trim() === '') {
      setValidationResult(null);
      return;
    }

    setIsLoading(true);

    // Replace this with your API call
    const isValid = await validateInput(value);
  };

  /**
   * 
   * @param {*} event 
   */
  const handleMessage = (event) => {
    setMessage(event.target.value);
  }

  /**
   * 
   * @param {*} event 
   */
  const handleChoosingMethod = (event) => {
    const x = event.target.value
    //console.log(x);
    //console.log(options.methods[x].title);

    setChosenMethod(x)
    setChosenMethodTitle(options.methods[x].title)
  }

  /**
   * 
   * @param {*} event 
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true)
    setShowSuccess(false)

    if (!validationResult) {
      setErrorMessage('The URL you provided is returning an error. The site must be up and active before it can be added to our system. Please check the URL you provided and try again.')
      setShowError(true)
      setIsSubmitting(false)

      window.scrollTo({ top: 0, behavior: 'smooth' });
      return
    }

    //if (url && interval && threshold) {
    if (url && chosenMethod) {
      // Call your function here
      submitInput()
    } else {
      // Handle missing values
      setErrorMessage('One or more of the fields is empty. All fields are required. Please check your values and try again.')
      setShowError(true)
      setIsSubmitting(false)

      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  /**
   * 
   * Rendered HTML stuff
   */
  return (
    <>
      {canAdd ? (
        <div className="relative pt-24 pb-32">
          <div className="px-4 md:px-10 max-w-5xl mx-5 md:mx-auto">
            <h1 className='mb-5 dark:text-white'>Add new site.</h1>
            <p className='dark:text-white mb-14'>We are monitoring <span className='font-bold'>{amount}</span> of your <span className='font-bold'>{allowed}</span> allowed site(s) for your account. You have <span className='font-bold'>{remaining}</span> sites remaining.</p>

            {showError ? (
              <Alert severity="error" variant="filled" className='mb-12' onClose={() => {setShowError(false)}}>
                <AlertTitle className='capitalize'>Error</AlertTitle>
                {errorMessage}
              </Alert>
            ) : (<></>)}

            {showSuccess ? (
              <Alert severity="success" variant="filled" className='mb-12' onClose={() => {setShowSuccess(false)}}>
                <AlertTitle className='capitalize'>Success</AlertTitle>
                Your website has been added. You can <Link to={`/site/${newId}/`}>view the stats page here.</Link> 
              </Alert>
            ) : (<></>)}
            
            <form onSubmit={handleSubmit} className='mt-8'>
              <label htmlFor="url" className='font-bold dark:text-white'><h4>Website URL</h4></label>
              <div className="flex bg-secondary justify-between items-center rounded-3xl px-8 py-6 mb-12 dark:border-none border border-solid border-gray-500">
                <input
                  id="url"
                  type="text"
                  value={url}
                  onChange={handleUrlChange}
                  onBlur={handleUrlChange}
                  className="appearance-none text-2xl flex-1 focus:outline-none bg-transparent"
                />
                {isLoading ? (
                  <FaSpinner className="text-blue-500 animate-spin" />
                ) : validationResult !== null ? (
                  <>
                    {validationResult ? (
                      <FaCheck className={validationResult ? 'text-green-500' : 'text-red-500'} />
                    ) : (
                      <FaTimes className={validationResult ? 'text-green-500' : 'text-red-500'} />
                    )}
                  </>
                ) : null}
              </div>
              <p className='dark:text-white mb-5'>Enter the url of your website above. Subdomains (subdomain.domain.com) and pages (domain.com/page) are allowed. Please include the http:// or https:// protocol with the domain.</p>

              {/** 
              <div className='max-w-5xl mx-auto p-8 lg:p-16 border border-solid dark:border-secondary border-primary rounded-3xl my-10 md:flex justify-between items-center'>
                <div className='md:w-2/3 md:mb-0 mb-5'>
                  <h4 className='dark:text-white tex-xl'>Interval</h4>
                  <p className='dark:text-white'>This is how often we will check the site. </p>
                </div>
                <div className='md:1/3 '>
                  {options ? (
                    <ThemeProvider theme={theme}>
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel className='dark:text-white'>Interval</InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={interval}
                          label="interval"
                          onChange={handleInterval}
                          className='dark:text-white'
                        >
                          {Object.keys(options.interval).map((key) => (
                            <MenuItem key={key} value={key}>
                              {options.interval[key]}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>Minutes</FormHelperText>
                      </FormControl>
                    </ThemeProvider>
                  ) : (<></>)}
                </div>
              </div>

              <div className='max-w-5xl mx-auto p-8 lg:p-16 border border-solid dark:border-secondary border-primary rounded-3xl my-10 md:flex justify-between items-center'>
                <div className='md:w-2/3 md:mb-0 mb-5'>
                  <h4 className='dark:text-white tex-xl'>Alarm Threshold</h4>
                  <p className='dark:text-white'>How many times must the site fail before we alert you? </p>
                </div>
                <div className='md:1/3 '>
                  {options ? (
                    <ThemeProvider theme={theme}>
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel className='dark:text-white'>Threshold</InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={threshold}
                          label="interval"
                          onChange={handleThreshold}
                          className='dark:text-white'
                        >
                          {Object.keys(options.threshold).map((key) => (
                            <MenuItem key={key} value={key}>
                              {options.threshold[key]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </ThemeProvider>
                  ) : (<></>)}
                </div>
              </div>

              <div className="bg-secondary dark:bg-primary justify-between items-center rounded-3xl px-8 mb-10 lg:p-16 border border-solid border-gray-500 dark:border-white dark:border-transparent">
                <label htmlFor="customMessage" className='font-bold dark:text-white'><h4>Custom Failure Message</h4></label>
                <p className='mb-5 dark:text-white'>Optional. Customize the message we send when your site is down. If you provide nothing, a default message will be sent.</p>
                <textarea 
                  name="customMessage" 
                  id="customMessage" 
                  placeholder='Custom Message' 
                  value={message}
                  onChange={handleMessage}
                  onBlur={handleMessage}
                  className='min-h-[150px] border border-solid rounded-xl border-gray-500 dark:border-white dark:text-white p-3 w-full bg-transparent focus:outline-none' 
                />
              </div>*/}

              <div className='max-w-5xl mx-auto p-8 lg:p-16 border border-solid dark:border-secondary border-primary rounded-3xl my-20'>
                <div className=' md:mb-0 mb-5'>
                  <h4 className='dark:text-white tex-xl'>Contact Method</h4>
                  <p className='dark:text-white'>Which verified contact method would you like to use for this site?</p>
                </div>
                <div className='pt-5'>
                  {options ? (
                    <ThemeProvider theme={theme}>
                      <FormControl sx={{ minWidth: '100%' }}>
                        <InputLabel className='dark:text-white'>Method</InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={chosenMethod}
                          label="method"
                          onChange={handleChoosingMethod}
                          className='dark:text-white'
                        >
                          {Object.keys(options.methods).map((key) => (
                            <MenuItem key={key} value={key}>
                              {options.methods[key].title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </ThemeProvider>
                  ) : (<></>)}
                </div>
              </div>

              {isSubmitting ? (
                <div className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer">
                  <FaSpinner className="text-white animate-spin" />
                </div>
              ) : (
                <input type="submit" value="Submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer" />
              )}            
            </form>

          </div>           
        </div>
      ) : (
        <div className='text-center min-h-screen -mt-10 flex mx-auto max-w-2xl flex-col justify-center items-center'>
          {(addError == 1) ? (
            <>
              <h1 className='text-center text-3xl lg:text-8xl dark:text-white mb-10'>Hold on.</h1>
              <FaThermometerFull className={'text-red-500 text-5xl lg:text-9xl mb-10'} />
              <h3 className='lg:text-xl text-md dark:text-white mb-8' dangerouslySetInnerHTML={{ __html: options.message }} />
              <p className='dark:text-white lg:text-2xl text-lg'>Your current limit:</p>
              <h2 className='text-2xl lg:text-5xl dark:text-white font-bold '>{options.limit}</h2>
            </>
          ) : (<></>)}
          
          {(addError == 2) ? (
            <>
              <h1 className='text-center text-3xl lg:text-8xl dark:text-white mb-10'>Hold On.</h1>
              <FaBan className={'text-red-500 text-5xl lg:text-9xl mb-10'} />
              <h3 className='lg:text-xl text-md dark:text-white mb-8' dangerouslySetInnerHTML={{ __html: options.message }} />
              <p className='dark:text-white lg:text-2xl text-lg'>
                <Button href="/settings/methods" title="Add Contact Method." icon="FaCirclePlus" extraClasses="py-2 px-4" />
              </p>
            </>
          ) : (<></>)}
        </div>
      )}
    </>
  )

  /**
   * 
   * @param {*} urlString 
   * @returns 
   */
  function isValidURL(urlString) {
    try {
      const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))');  // OR ip (v4) address
        
      return !!pattern.test(urlString);
    } catch (e) {
      return false;
    }

    /*'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator*/
  }

  /**
   * 
   */
  function submitInput() {
    var myHeaders = new Headers();
    myHeaders.append("wsm-session-token", token);

    var formdata = new FormData();
    formdata.append("url", url);
    formdata.append("cronInterval", interval);
    formdata.append("threshold", threshold);
    formdata.append("message", message);
    formdata.append('contact_method', options.methods[chosenMethod].id)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "/addsite", requestOptions)
      .then(response => response.json())
      .then(result => {
        setIsSubmitting(false)
        
        //console.log(result)
        if (result.code == 200) {
          setNewId(result.id)
          setShowSuccess(true)
          setShowError(false)
          setReloadTrigger(!reloadTrigger);

          setUrl('https://')
          setInterval('')
          setThreshold('')
          setMessage('')
          setChosenMethod('')

          setReloadTrigger(prev => prev + 1)
          refreshSidebar()
        } else {
          setErrorMessage(result.message)
          setShowError(true)
        }

        window.scrollTo({ top: 0, behavior: 'smooth' })
      })
      .catch(error => {
        //console.log('error', error)
        setErrorMessage('An unexpected error occured. Please try again.')
        setShowError(true)
        window.scrollTo({ top: 0, behavior: 'smooth' })
      });
  }

  /**
   * 
   * @param {*} value 
   * @returns 
   */
  async function validateInput(value) {
    if (isValidURL(value)) {
      var myHeaders = new Headers();
      myHeaders.append("wsm-session-token", token);

      var formdata = new FormData();
      formdata.append("url", value);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(API_URL + '/quickcheck', requestOptions)
        .then(response => response.json())
        .then(result => {
          let isValid = false;
          
          if (result.code == 200) {
            setButtonDisabled(false)
            isValid = true
          } else {
            isValid = false
          }

          setIsLoading(false);
          setValidationResult(isValid);
        })
        .catch(error => {
          //console.log('error', error)
          setErrorMessage('An unexpected error occured. Please try again.')
          setShowError(true)
          window.scrollTo({ top: 0, behavior: 'smooth' })
        });
    }
  }
}

export default Add