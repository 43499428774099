import React, { useEffect, useState } from 'react'
import { useSettings } from '../context/SettingsContext'
import { FaPlusCircle, FaSpinner } from "react-icons/fa";
import Alert from '@mui/material/Alert';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';

import { API_URL } from './parts/Constants';

const ContactMethods = () => {
  const settings = useSettings();
  const token = settings.userToken;
  const [mode, setMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );
  //vars
  const [counter, setCounter] = useState(0)
  const [methods, setMethods] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [title, setTitle] = useState('')
  const [method, setMethod] = useState('')
  const [allowed, setAllowed] = useState(false)
  const [email, setEmail] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [chatId, setChatId] = useState('')
  const [methodTypes, setMethodTypes] = useState(null)
  //toggles
  const [showAlert, setShowAlert] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showAdd, setShowAdd] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showEmailFields, setShowEmailFields] = useState(false);
  const [showTelegramFields, setShowTelegramFields] = useState(false);

  /**
   * 
   */
  useEffect(() => {
    // doc title
    document.title = "Contact Methods | SiteStatusMontoring.com"

    // detect dark mode
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => {
      setMode(mediaQuery.matches ? 'dark' : 'light');
    };
    mediaQuery.addEventListener('change', handleChange);
    
    // get options for user
    var myHeaders = new Headers();
    myHeaders.append("wsm-session-token", token);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(API_URL + "/getcontactmethods", requestOptions)
      .then(response => response.json())
      .then(results => {
        //console.log(results);
        if (results.code == 200) {
          setMethods(results.methods)
          setMethodTypes(results.types)

          if (results.allowed == 1) {
            setAllowed(true)
          }
        }    
      })
      .catch(error => console.log('error', error));
    
    // Cleanup function to remove the listener when the component unmounts
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [counter])

  /**
   * 
   */
  const theme = createTheme({
    palette: {
      mode: mode,
    },
  });

  /**
   * 
   */
  const handleSubmit = (event) => {
    event.preventDefault()
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setIsSubmitting(true)
    setShowAlert(false)
    setShowError(false)

    //main checks
    if (!title && !method) {
      setAlertMessage('Sorry, but the title and method fields required. Please provide them.')
      setShowError(true)
      setIsSubmitting(false)
      return;
    }

    //checks
    if (method === 'email') {
      if (!email) {
        setAlertMessage('Sorry, but the email address is required. Please try again.')
        setShowError(true)
        setIsSubmitting(false)
        return;
      }

      //verify email
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!emailRegex.test(email)) {
        setAlertMessage('Sorry, but the email address you provided is invalid. Please try again.')
        setShowError(true)
        setIsSubmitting(false)
        return;
      }
    }

    if (method === 'telegram') {
      if (!apiKey && !chatId) {
        setAlertMessage('Sorry, but Telegram requires an API Key and Chat ID. Please try again.')
        setShowError(true)
        setIsSubmitting(false)
        return;
      }
    }

    submitNew()
  }

  /**
   * 
   * @param {*} event 
   */
  const handleMethod = (event) => {
    const m = event.target.value

    setShowEmailFields(false)
    setShowTelegramFields(false)
    
    switch (m) {
      case 'email':
        setShowEmailFields(true)
      break;

      case 'telegram':
        setShowTelegramFields(true)
      break;
    }
    
    setMethod(m)
  }

  return (
    <div className="relative pt-24 pb-32">
      <div className="px-4 md:px-10 max-w-5xl mx-5 md:mx-auto">
        <div className='flex justify-between items-center mb-5'>
          <h1 className='my-0 dark:text-white'>Contact Methods</h1>
          {allowed ? (
            <div onClick={() => {setShowAdd(showAdd => !showAdd)}} className={`text-secondary font-semibold border-2 cursor-pointer border-accent border-solid items-center justify-center hover:bg-transparent bg-accent hover:text-accent rounded-full inline-flex py-1 px-4`}>
              Add New
              <FaPlusCircle className='ml-2' />
            </div>
          ) : (<></>)}
        </div>
        <hr className='dark:border-white border-primary mb-10' />
        <p className='mb-5 text-primary dark:text-white'>Here you can add, edit, and remove methods we can use to contact you when a site is having trouble. <br /><br />Please note that all contact methods will need to be verified before we will use them. Don't worry, we will walk you through the steps.</p>

        {showAlert ? (
          <Alert severity="success" variant="filled" className='my-12' onClose={() => {setShowAlert(false)}}>
            {alertMessage}
          </Alert>
        ) : (<></>)}

        {showError ? (
          <Alert severity="error" variant="filled" className='my-12' onClose={() => {setShowError(false)}}>
            {alertMessage}
          </Alert>
        ) : (<></>)}

        {showAdd ? (
          <form onSubmit={handleSubmit} className='max-w-5xl mx-auto p-8 lg:p-16 border border-solid dark:border-secondary border-primary rounded-3xl my-10'>
            <h4 className='dark:text-white text-xl'>Title</h4>
            <p className='dark:text-white mb-5'>A simple title you can use to identify the contact method.</p>
            <input
              id="title"
              type="text"
              placeholder='Contact Method Title'
              value={title}
              onChange={(event) => {
                setTitle(event.target.value)
              }}
              onBlur={(event) => {
                setTitle(event.target.value)
              }}
              className="text-lg rounded-2xl py-2 px-5 w-full border border-solid border-primary"
            />

            <hr className='border border-solid border-primary dark:border-white my-8  opacity-50' />
            
            <div className='md:flex justify-between items-center'>
              <div className='md:w-2/3 md:mb-0 mb-5'>
                <h4 className='dark:text-white text-xl'>Contact Method</h4>
                <p className='dark:text-white'>This is how often we will check the site. </p>
              </div>
              <div className='md:1/3 '>
                <ThemeProvider theme={theme}>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel className='dark:text-white'>Please Select</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={method}
                      label="interval"
                      onChange={handleMethod}
                    >
                      {Object.keys(methodTypes).map((key) => (
                        <MenuItem key={key} value={key}>
                          {methodTypes[key]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </ThemeProvider>
              </div>
            </div>

            {showEmailFields ? (
              <>
                <hr className='border border-solid border-primary dark:border-white my-8 opacity-50' />

                <h4 className='dark:text-white text-xl'>Email Address</h4>
                <p className='dark:text-white mb-5'>Please provide an email address you would like the alert to go to.</p>
                <input
                  id="email"
                  type="text"
                  placeholder='Email Address'
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                  onBlur={(event) => {
                    setEmail(event.target.value)
                  }}
                  className="text-lg rounded-2xl py-2 px-5 w-full"
                />
              </>
            ) : (<></>)}

            {showTelegramFields ? (
              <>
                <hr className='border border-solid border-primary dark:border-white my-8 opacity-50' />

                <h4 className='dark:text-white text-xl'>Telegram Bot Chat</h4>
                <p className='dark:text-white mb-5'>In order to use Telegram, you must has a Bot setup for the Telegram platform. Don't worry. They are free. Here is <a className='underline' href="https://core.telegram.org/bots/api">Telegram's bot documentation.</a></p>
                <input
                  id="api_key"
                  type="text"
                  placeholder='API Key'
                  value={apiKey}
                  onChange={(event) => {
                    setApiKey(event.target.value)
                  }}
                  onBlur={(event) => {
                    setApiKey(event.target.value)
                  }}
                  className="text-lg rounded-2xl py-2 px-5 w-full mb-3"
                />
                <input
                  id="chat_id"
                  type="text"
                  placeholder='Chat ID'
                  value={chatId}
                  onChange={(event) => {
                    setChatId(event.target.value)
                  }}
                  onBlur={(event) => {
                    setChatId(event.target.value)
                  }}
                  className="text-lg rounded-2xl py-2 px-5 w-full"
                />
              </>
            ) : (<></>)}

            {isSubmitting ? (
              <div className="group relative w-full flex justify-center mt-10 py-3 px-4 border border-transparent text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer">
                <FaSpinner className="text-white animate-spin" />
              </div>
            ) : (
              <input type="submit" value="Submit" className="group relative mt-10 w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer" />
            )}   
          </form>
        ) : (<></>)}

        {methods ? (
          <>
          <h2 className='dark:text-white text-primary mt-14 mb-7'>Currect methods:</h2>
          {methods.map((method, index) => (
            <Link to={`/settings/methods/${method.id}`} key={index} className="py-3 px-8 md:min-w-full text-blueGray-500 bg-secondary rounded-2xl drop-shadow mb-5 no-underline cursor-pointer flex items-center justify-between">
              <span className='font-bold'>{method.title} <span className='font-bold capitalize text-xs'>({method.method})</span></span>
              <span className='capitalize'>Verified: {method.verified == 0 ? (<span className='text-red-500'>No</span>) : (<span className='text-green-500'>Yes</span>)}</span>
            </Link>
          ))}
          </>
        ) : (<></>)}
      </div>
    </div>
  )

  /**
   * 
   */
  function submitNew() {
    var myHeaders = new Headers();
    myHeaders.append("wsm-session-token", token);

    var formdata = new FormData();
    formdata.append("title", title);
    formdata.append("method", method);

    switch (method) {
      case 'email':
        formdata.append("email", email);
      break;

      case 'telegram':
        formdata.append("chat_id", chatId);
        formdata.append("api_key", apiKey);
      break;
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "/addcm", requestOptions)
      .then(response => response.json())
      .then(result => {
        setIsSubmitting(false)
        setAlertMessage(result.message)
        
        //console.log(result)
        if (result.code == 200) {
          setShowAdd(false)
          setShowTelegramFields(false)
          setShowEmailFields(false)
          
          setMethod('')
          setTitle('')
          setEmail('')
          setApiKey('')
          setChatId('')

          setShowAlert(true)
          refreshComponent()
        } else {
          setShowError(true)
        }
      })
      .catch(error => console.log('error', error));
  }

  /**
   * Refreshes component
   */
  function refreshComponent() {
    setCounter(prevCounter => prevCounter + 1); // Any change in state triggers a re-render
  };
}

export default ContactMethods